import { get, keys } from 'lodash';
import { getSettings } from './settings';

export const getThemes = state => get(state, 'subscriptions.physicproducts.data');
export const areThemesLoading = state => get(state, 'subscriptions.physicproducts.loading', false);
export const areThemesInitialized = state => keys(get(state, 'subscriptions.physicproducts.data')).length > 0;
export const areThemesUnavailable = state => (areThemesInitialized(state) && getThemes(state).new) === undefined;

export const getTheme = (state, { idtheme }) => {
  const { view } = getSettings(state);
  const themes = getThemes(state);

  if (!themes || !themes[view]) return null;

  return themes[view].products.filter(theme => theme.idtheme === idtheme)[0];
};

export const getSelectedTheme = state => {
  const { selectedIdtheme } = getSettings(state);
  return getTheme(state, { idtheme: selectedIdtheme });
};

export const getSelectedPhysicproduct = state => {
  const { selectedIdphysicproduct } = getSettings(state);
  if (!selectedIdphysicproduct) return null;

  const theme = getSelectedTheme(state);
  if (!theme) return null;

  return theme.physicproducts.filter(p => p.idp === selectedIdphysicproduct)[0];
};

export const getPromocodeStatus = state => {
  const themes = getThemes(state);
  const { view } = getSettings(state);

  return get(themes, `${view}.promotion_from_promocode.status`, 1);
};
