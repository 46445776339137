import {
  SEARCH_SCOPE_CLASSIFIEDS,
  SEARCH_SCOPE_LOTS,
  SEARCH_SCOPE_MARKETPLACE_MANAGE_MODIFICATION_REQUIRED,
  SEARCH_SCOPE_MARKETPLACE_MANAGE_MY_OFFERS,
  SEARCH_SCOPE_MARKETPLACE_MANAGE_OFFLINE,
  SEARCH_SCOPE_MARKETPLACE_MANAGE_ONLINE,
  SEARCH_SCOPE_MARKETPLACE_MANAGE_WAITING,
  SEARCH_SCOPE_MARKETPLACE_MANAGE_WINNING_AUCTIONS,
  SEARCH_SCOPE_MARKETPLACE_MANAGE_WITH_OFFERS,
  SEARCH_SCOPE_MYARTPRICE_STORE,
  SEARCH_SCOPE_MYARTPRICE_LOTS_AUCTIONED_OFF,
  SEARCH_SCOPE_MYARTPRICE_LOTS_FUTURE_ALL,
  SEARCH_SCOPE_MYARTPRICE_LOTS_MARKETPLACE,
  SEARCH_SCOPE_MYARTPRICE_LOTS_FUTURE_NEW,
  SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_FUTURE,
  SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_PAST,
  SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_MARKETPLACE,
  SEARCH_SCOPE_MYARTPRICE_MARKETPLACE,
  SEARCH_SCOPE_MYARTPRICE_LOTS_HISTORY,
  SEARCH_SCOPE_STORES,
  SEARCH_SCOPE_CLASSIFIEDS_IN_STORE,
  SEARCH_SCOPE_AUCTIONEER_FUTURE_SALES,
  SEARCH_SCOPE_AUCTIONEER_PAST_SALES,
  SEARCH_SCOPE_AUCTIONEER_SALE,
  SEARCH_SCOPE_AUCTIONEER_SALE_LOTS,
  SEARCH_SCOPE_ARTIST_LOTS_PAST,
  SEARCH_SCOPE_ARTIST_LOTS_FUTURE,
  SEARCH_SCOPE_ARTIST_LOTS_ALL,
  SEARCH_SCOPE_SALE_LOTS,
  SEARCH_SCOPE_SALES_FUTURES,
  SEARCH_SCOPE_ARTISTS,
} from '../rails';

export const CLASSIFIEDS = SEARCH_SCOPE_CLASSIFIEDS;
export const STORES = SEARCH_SCOPE_STORES;
export const LOTS = SEARCH_SCOPE_LOTS;
export const AUCTIONEER_PAST_SALES = SEARCH_SCOPE_AUCTIONEER_PAST_SALES;
export const AUCTIONEER_SALE_LOTS = SEARCH_SCOPE_AUCTIONEER_SALE_LOTS;
export const AUCTIONEER_FUTURE_SALES = SEARCH_SCOPE_AUCTIONEER_FUTURE_SALES;
export const AUCTIONEER_SALE = SEARCH_SCOPE_AUCTIONEER_SALE;
export const MYARTPRICE_STORE = SEARCH_SCOPE_MYARTPRICE_STORE;
export const MYARTPRICE_LOTS_MARKETPLACE = SEARCH_SCOPE_MYARTPRICE_LOTS_MARKETPLACE;
export const MYARTPRICE_LOTS_FUTURE_ALL = SEARCH_SCOPE_MYARTPRICE_LOTS_FUTURE_ALL;
export const MYARTPRICE_LOTS_FUTURE_NEW = SEARCH_SCOPE_MYARTPRICE_LOTS_FUTURE_NEW;
export const MYARTPRICE_LOTS_FOLLOW_FUTURE = SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_FUTURE;
export const MYARTPRICE_LOTS_FOLLOW_PAST = SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_PAST;
export const MYARTPRICE_LOTS_FOLLOW_MARKETPLACE = SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_MARKETPLACE;
export const MYARTPRICE_LOTS_AUCTIONED_OFF = SEARCH_SCOPE_MYARTPRICE_LOTS_AUCTIONED_OFF;
export const MYARTPRICE_MARKETPLACE = SEARCH_SCOPE_MYARTPRICE_MARKETPLACE;
export const MARKETPLACE_MANAGE_ONLINE = SEARCH_SCOPE_MARKETPLACE_MANAGE_ONLINE;
export const MARKETPLACE_MANAGE_WAITING = SEARCH_SCOPE_MARKETPLACE_MANAGE_WAITING;
export const MARKETPLACE_MANAGE_OFFLINE = SEARCH_SCOPE_MARKETPLACE_MANAGE_OFFLINE;
export const MARKETPLACE_MANAGE_MODIFICATION_REQUIRED = SEARCH_SCOPE_MARKETPLACE_MANAGE_MODIFICATION_REQUIRED;
export const MARKETPLACE_MANAGE_WITH_OFFERS = SEARCH_SCOPE_MARKETPLACE_MANAGE_WITH_OFFERS;
export const MARKETPLACE_MANAGE_MY_OFFERS = SEARCH_SCOPE_MARKETPLACE_MANAGE_MY_OFFERS;
export const MARKETPLACE_MANAGE_WINNING_AUCTIONS = SEARCH_SCOPE_MARKETPLACE_MANAGE_WINNING_AUCTIONS;
export const CLASSIFIEDS_IN_STORE = SEARCH_SCOPE_CLASSIFIEDS_IN_STORE;
export const TOTAL_ACCESS = 'TOTAL_ACCESS';
export const TOTAL_ACCESS_LOTS = 'TOTAL_ACCESS_LOTS';
export const TOTAL_ACCESS_ARTISTS = 'TOTAL_ACCESS_ARTISTS';
export const TOTAL_ACCESS_AUCTIONEERS = 'TOTAL_ACCESS_AUCTIONEERS';
export const TOTAL_ACCESS_GEO = 'TOTAL_ACCESS_GEO';
export const TOTAL_ACCESS_CHRONO = 'TOTAL_ACCESS_CHRONO';
export const ARTISTS = SEARCH_SCOPE_ARTISTS;
export const ARTIST_LOTS_PAST = SEARCH_SCOPE_ARTIST_LOTS_PAST;
export const ARTIST_LOTS_FUTURE = SEARCH_SCOPE_ARTIST_LOTS_FUTURE;
export const ARTIST_LOTS_ALL = SEARCH_SCOPE_ARTIST_LOTS_ALL;
export const SALE_LOTS = SEARCH_SCOPE_SALE_LOTS;
export const MYARTPRICE_LOTS_HISTORY = SEARCH_SCOPE_MYARTPRICE_LOTS_HISTORY;
export const SALES_FUTURES = SEARCH_SCOPE_SALES_FUTURES;

export const ALL_SCOPES = [
  AUCTIONEER_PAST_SALES,
  AUCTIONEER_FUTURE_SALES,
  AUCTIONEER_SALE_LOTS,
  AUCTIONEER_SALE,
  CLASSIFIEDS,
  STORES,
  LOTS,
  ARTISTS,
  MYARTPRICE_LOTS_FUTURE_ALL,
  MYARTPRICE_LOTS_FUTURE_NEW,
  MYARTPRICE_LOTS_AUCTIONED_OFF,
  MYARTPRICE_LOTS_MARKETPLACE,
  MYARTPRICE_LOTS_FOLLOW_FUTURE,
  MYARTPRICE_LOTS_FOLLOW_PAST,
  MYARTPRICE_LOTS_FOLLOW_MARKETPLACE,
  MYARTPRICE_STORE,
  MYARTPRICE_MARKETPLACE,
  MARKETPLACE_MANAGE_ONLINE,
  MARKETPLACE_MANAGE_WAITING,
  MARKETPLACE_MANAGE_OFFLINE,
  MARKETPLACE_MANAGE_MODIFICATION_REQUIRED,
  MARKETPLACE_MANAGE_WITH_OFFERS,
  MARKETPLACE_MANAGE_MY_OFFERS,
  MARKETPLACE_MANAGE_WINNING_AUCTIONS,
  CLASSIFIEDS_IN_STORE,
  TOTAL_ACCESS,
  ARTIST_LOTS_PAST,
  ARTIST_LOTS_FUTURE,
  ARTIST_LOTS_ALL,
  SALE_LOTS,
  SALES_FUTURES,
  MYARTPRICE_LOTS_HISTORY,
];

export const MYARTPRICE_LOTS = [MYARTPRICE_LOTS_AUCTIONED_OFF, MYARTPRICE_LOTS_FUTURE_ALL, MYARTPRICE_LOTS_FUTURE_NEW, MYARTPRICE_LOTS_MARKETPLACE];
export const MYARTPRICE_LOTS_FOLLOW = [MYARTPRICE_LOTS_FOLLOW_PAST, MYARTPRICE_LOTS_FOLLOW_FUTURE, MYARTPRICE_LOTS_FOLLOW_MARKETPLACE];
