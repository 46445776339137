import { updateArtists as updateArtistsEntities } from '../dbEntities/artist';
import { fetchArtists as fetchArtistsService, fetchSummary as fetchSummaryService, followArtist as followArtistService, unfollowArtist as unfollowArtistservice } from '../../../services/myartprice/artists';
import { followLot as followLotService, unfollowLot as unfollowLotservice, similarLot as similarLotService } from '../../../services/myartprice/lots';
import { followClassified as followClassifiedService, unfollowClassified as unfollowClassifiedService, fetchFavoriteClassifieds } from '../../../services/marketplace/classifieds';
import { areClassifiedsDataLoading, areDataLoading, areStoresDataLoading } from '../../selectors/myartprice/data';
import { fetchSettings } from './settings';
import { add as followStoreService, fetchFavoriteStores, remove as unfollowStoreService } from '../../../services/marketplace/stores/favorite';

export const UPDATE_ARTISTS = 'myartprice/data/UPDATE_ARTISTS';
export const updateArtists = ({ error, loading, artists, lvl }) => ({
  type: UPDATE_ARTISTS,
  payload: {
    artists,
    loading,
    error,
    lvl,
  },
});

export const fetchSummary = () => async (dispatch, getState) => {
  const state = getState();
  if (areDataLoading(state)) return;

  try {
    dispatch(updateArtists({ loading: true }));
    dispatch(fetchSettings());
    const { artists, data } = await fetchSummaryService();
    dispatch(updateArtistsEntities({ artists }));
    dispatch(updateArtists({ loading: false, artists: data }));
  } catch (error) {
    console.error(error);
    dispatch(updateArtists({ loading: false, error: error.message }));
  }
};

export const fetchArtists = () => async (dispatch, getState) => {
  const state = getState();
  if (areDataLoading(state)) return;

  try {
    dispatch(updateArtists({ loading: true }));
    const { artists, data } = await fetchArtistsService();
    dispatch(updateArtistsEntities({ artists }));
    dispatch(updateArtists({ loading: false, artists: data }));
  } catch (error) {
    console.error(error);
    dispatch(updateArtists({ loading: false, error: error.message }));
  }
};

// Artist
export const followArtist =
  ({ idartist }) =>
  async dispatch => {
    const { lvl, error, artists, data } = await followArtistService(idartist);
    dispatch(updateArtists({ loading: false, artists: data, error, lvl }));
    dispatch(updateArtistsEntities({ artists }));
  };

export const UPDATE_MYARTPRICE_CURRENT_PAGE = 'myartprice/data/UPDATE_MYARTPRICE_CURRENT_PAGE';
export function updateCurrentPage(payload) {
  return {
    type: UPDATE_MYARTPRICE_CURRENT_PAGE,
    payload,
  };
}

export const UPDATE_MYARTPRICE_ENTITIES = 'myartprice/data/UPDATE_MYARTPRICE_ENTITIES';
export function updateEntities(payload) {
  return {
    type: UPDATE_MYARTPRICE_ENTITIES,
    payload,
  };
}

export const UPDATE_MYARTPRICE_LOADING = 'myartprice/data/UPDATE_MYARTPRICE_LOADING';
export function updateLoading(payload) {
  return {
    type: UPDATE_MYARTPRICE_LOADING,
    payload,
  };
}

export const UPDATE_MYARTPRICE_TOTAL_COUNT = 'myartprice/data/UPDATE_MYARTPRICE_TOTAL_COUNT';
export function updateTotalCount(payload) {
  return {
    type: UPDATE_MYARTPRICE_TOTAL_COUNT,
    payload,
  };
}

export const UPDATE_MYARTPRICE_ID_ENTITIES = 'myartprice/data/UPDATE_MYARTPRICE_ID_ENTITIES';
export function updateIdEntities(payload) {
  return {
    type: UPDATE_MYARTPRICE_ID_ENTITIES,
    payload,
  };
}

export const UPDATE_MYARTPRICE_SEARCH_SCOPE = 'myartprice/data/UPDATE_MYARTPRICE_SEARCH_SCOPE';
export function updateSearchScope(payload) {
  return {
    type: UPDATE_MYARTPRICE_SEARCH_SCOPE,
    payload,
  };
}

export const INCLUDE_IN_PUSH = 'myartprice/artists/INCLUDE_IN_PUSH';
export const includeInPushArtist =
  ({ idartist }) =>
  async dispatch => {
    dispatch({
      type: INCLUDE_IN_PUSH,
      payload: {
        idartist,
      },
    });
  };

export const UNFOLLOW_ARTIST = 'myartprice/artists/UNFOLLOW_ARTIST';
export const unfollowArtist =
  ({ idartists }) =>
  async dispatch => {
    await unfollowArtistservice(idartists);
    dispatch({
      type: UNFOLLOW_ARTIST,
      payload: {
        idartists,
      },
    });
  };

// Lots
export const ADD_LOT_FOLLOW = 'myartprice/lots/ADD_LOT_FOLLOW';
export function addLotFollow(payload) {
  return {
    type: ADD_LOT_FOLLOW,
    payload,
  };
}

export const REMOVE_LOT_FOLLOW = 'myartprice/lots/REMOVE_LOT_FOLLOW';
export function removeLotFollow(payload) {
  return {
    type: REMOVE_LOT_FOLLOW,
    payload,
  };
}

export const followLot = idlot => async dispatch => {
  const status = await followLotService(idlot);
  if (status.unauthorized !== true) {
    dispatch(addLotFollow({ loading: false, idlot }));
  }
  return status;
};

export const unfollowLot = idlot => async dispatch => {
  const status = await unfollowLotservice(idlot);
  dispatch(removeLotFollow({ loading: false, status, idlot }));
};

// Classifieds

export const UPDATE_FAVORITE_CLASSIFIEDS = 'myartprice/data/UPDATE_FAVORITE_CLASSIFIEDS';
const updateFavoriteClassifieds = ({ error, loading, data }) => ({
  type: UPDATE_FAVORITE_CLASSIFIEDS,
  payload: {
    data,
    loading,
    error,
  },
});

export const ADD_CLASSIFIED_FOLLOW = 'myartprice/classifieds/ADD_CLASSIFIED_FOLLOW';
function addClassifiedFollowAction(payload) {
  return {
    type: ADD_CLASSIFIED_FOLLOW,
    payload,
  };
}

export const REMOVE_CLASSIFIED_FOLLOW = 'myartprice/classifieds/REMOVE_CLASSIFIED_FOLLOW';
function removeClassifiedFollowAction(payload) {
  return {
    type: REMOVE_CLASSIFIED_FOLLOW,
    payload,
  };
}

export const followClassified = idclassified => async dispatch => {
  await followClassifiedService(idclassified);
  dispatch(addClassifiedFollowAction({ loading: false, idclassified }));
};

export const unfollowClassified = idclassified => async dispatch => {
  const status = await unfollowClassifiedService(idclassified);
  dispatch(removeClassifiedFollowAction({ loading: false, status, idclassified }));
};

export const fetchClassifieds = () => async (dispatch, getState) => {
  const state = getState();
  if (areClassifiedsDataLoading(state)) return;

  try {
    dispatch(updateFavoriteClassifieds({ loading: true }));
    const data = await fetchFavoriteClassifieds();
    dispatch(updateFavoriteClassifieds({ loading: false, data }));
  } catch (error) {
    console.error(error);
    dispatch(updateFavoriteClassifieds({ loading: false, error }));
  }
};

// Lot Similar
export const GET_LOT_SIMILAR = 'myartprice/lots/SIMILAR';
export function getLotSimilarAction(payload) {
  return {
    type: GET_LOT_SIMILAR,
    payload,
  };
}

export const getLotSimilar = idlot => async dispatch => {
  try {
    const res = await similarLotService(idlot);
    dispatch(getLotSimilarAction({ loading: false, res }));
  } catch (error) {
    console.error(error);
    dispatch(getLotSimilarAction({ loading: false, res: {} }));
  }
};

// Store

export const ADD_STORE_FOLLOW = 'myartprice/classifieds/ADD_STORE_FOLLOW';
function addStoreFollowAction(payload) {
  return {
    type: ADD_STORE_FOLLOW,
    payload,
  };
}

export const REMOVE_STORE_FOLLOW = 'myartprice/classifieds/REMOVE_STORE_FOLLOW';
function removeStoreFollowAction(payload) {
  return {
    type: REMOVE_STORE_FOLLOW,
    payload,
  };
}

export const UPDATE_FAVORITE_STORES = 'myartprice/data/UPDATE_FAVORITE_STORES';
const updateFavoriteStore = ({ error, loading, data }) => ({
  type: UPDATE_FAVORITE_STORES,
  payload: {
    data,
    loading,
    error,
  },
});

export const fetchStores = () => async (dispatch, getState) => {
  const state = getState();
  if (areStoresDataLoading(state)) return;

  try {
    dispatch(updateFavoriteStore({ loading: true }));
    const data = await fetchFavoriteStores();
    dispatch(updateFavoriteStore({ loading: false, data }));
  } catch (error) {
    console.error(error);
    dispatch(updateFavoriteStore({ loading: false, error }));
  }
};

export const followStore = idstore => async dispatch => {
  const status = await followStoreService(idstore);
  dispatch(addStoreFollowAction({ loading: false, status, idstore }));
};

export const unfollowStore = idstore => async dispatch => {
  const status = await unfollowStoreService(idstore);
  dispatch(removeStoreFollowAction({ loading: false, status, idstore }));
};
