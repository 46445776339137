import { combineReducers } from 'redux';
import eventsReducer from './events';
import indexesReducer from './indexes';
import marketplaceReducer from './marketplace';
import myartpriceReducer from './myartprice';
import uiReducer from './ui';
import indicatorReducer from './indicator';
import auctioneersReducer from './auctioneers';
import preferencesReducer from './preferences';
import { UPDATE_REDUX_STORE } from '../actions';
import dbEntitiesReducer from './dbEntities';
import accountReducer from './account';
import agreementsReducer from './agreements';
import subscriptionsReducer from './subscriptions';

export default function createRootReducer(otherReducers = {}) {
  const combinedReducers = combineReducers({
    events: eventsReducer,
    indexes: indexesReducer,
    indicator: indicatorReducer,
    preferences: preferencesReducer,
    marketplace: marketplaceReducer,
    myartprice: myartpriceReducer,
    dbEntities: dbEntitiesReducer,
    subscriptions: subscriptionsReducer,
    auctioneers: auctioneersReducer,
    ui: uiReducer,
    account: accountReducer,
    agreements: agreementsReducer,
    ...otherReducers,
  });

  return function rootReducer(state, action) {
    if (action.type === UPDATE_REDUX_STORE) {
      // eslint-disable-next-line no-param-reassign
      state = action.data;
    }

    return combinedReducers(state, action);
  };
}
