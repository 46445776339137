import { updateArtistInfos, updateArtistOptions, updateDisplay } from './artist';
import { updateSummaryData } from './summary';
import { updatePriceIndexArtistData } from './priceIndex';

/* eslint-disable import/prefer-default-export */
export const updateIndexesState = reduxState => dispatch => {
  const { currency, artist: { id, options: artistOptions, ...artistInfos } = {}, display, summary, priceIndex } = reduxState;
  dispatch(updateArtistInfos({ idartist: id, loading: false, data: artistInfos }));
  dispatch(updateArtistOptions({ idartist: id, currency, loading: false, data: artistOptions }));
  dispatch(updateSummaryData({ idartist: id, currency, loading: false, data: summary }));
  dispatch(updateDisplay({ idartist: id, loading: false, data: display }));
  dispatch(updatePriceIndexArtistData({ idartist: id, currency, loading: false, data: priceIndex }));
};
