import { internalAxios } from '../../../utils/axios/internal';

export const classifiedsForCustomerAndArtist = async idclassified => {
  try {
    const response = await internalAxios.get(`/api/marketplace/show/classifieds_for_customer_and_artist/${idclassified}?format=json`);
    return response.data;
  } catch (error) {
    console.error(error);
    return { count: 0, items: [] };
  }
};

export const classifiedsForCustomer = async idclassified => {
  try {
    const response = await internalAxios.get(`/api/marketplace/show/classifieds_for_customer_and_artist/${idclassified}?format=json&only_idartist=1`);
    return response.data;
  } catch (error) {
    console.error(error);
    return { count: 0, items: [] };
  }
};

export const fetchFavoriteClassifieds = async () => {
  const response = await internalAxios.get('/api/wallet/classified/favorites');
  return response.data;
};

export const followClassified = async idclassified => {
  const response = await internalAxios.get(`/api/wallet/classified/add_in_favorite/${idclassified}?format=json`);
  return response.data;
};

export const unfollowClassified = async idclassified => {
  const response = await internalAxios.get(`/api/wallet/classified/remove_from_favorite/${idclassified}?format=json`);
  return response.data;
};

export const createMessage = async ({ message, idclassified, idcustomerInterlocutor }) => {
  const response = await internalAxios.post('/api/wallet/classifiedmessage/create_message?format=json', { text: message, idclassified, idcustomer_interlocutor: idcustomerInterlocutor });
  return response.data;
};

export const retrieveMessage = async idclassified => {
  const response = await internalAxios.post('/api/wallet/classifiedmessage/get_conversation?format=json', { idclassified });
  return response.data;
};
