import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getAllArtists } from '../dbEntities';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants/utils';

const artistsFromState = state => get(state, 'myartprice.data.artists', EMPTY_OBJECT);
const artistFromState = (state, { idartist }) => get(artistsFromState(state), idartist);
export const areDataLoading = state => get(state, 'myartprice.data.loading');
export const areClassifiedsDataLoading = state => get(state, 'myartprice.data.favoriteClassified.loading');
export const areStoresDataLoading = state => get(state, 'myartprice.data.favoriteStore.loading');

export const getArtists = createSelector(artistsFromState, getAllArtists, (dataArtistsFromFavorites, artistdataFromDbEntities) => {
  const artists = [];
  if (dataArtistsFromFavorites) {
    Object.entries(dataArtistsFromFavorites).forEach(([id, data]) => {
      if (artistdataFromDbEntities[id]) {
        const artist = artistdataFromDbEntities[id];
        artists.push({
          ...artist,
          ...data,
        });
      }
    });
  }
  return artists;
});

const artistsSorted = (artists, sort, direction) => {
  artists.sort((a, b) => {
    if (a[sort] > b[sort]) {
      return direction === 'asc' ? 1 : -1;
    }

    if (a[sort] < b[sort]) {
      return direction === 'asc' ? -1 : 1;
    }

    return 0;
  });
  return artists;
};

export const getSortedArtists = (state, { sort = 'name_for_sorting', direction = 'asc' }) => {
  const artists = getArtists(state);
  return artistsSorted(artists, sort, direction);
};

export const getSearchScope = state => get(state, 'myartprice.data.searchScope', '');

export const getIds = state => get(state, `myartprice.data.ids`, EMPTY_ARRAY);

export const getEntities = state => get(state, `myartprice.data.entities`, EMPTY_OBJECT);

export const getCurrentPage = state => get(state, `myartprice.data.page`, 1);

export const getTotalCount = state => get(state, `myartprice.data.totalCount`, 0);

export const getLoading = state => get(state, `myartprice.data.loading`, false);

export const getArtistParam = (state, { idartist, param }) => get(artistFromState(state, { idartist }), param);

export const getFavoriteLot = state => get(state, 'myartprice.data.favoriteLot.data', EMPTY_ARRAY);

export const getIsFavoritedLot = (state, idlot) => get(state, 'myartprice.data.favoriteLot.data', EMPTY_ARRAY).includes(idlot);

export const getFavoriteClassified = state => get(state, 'myartprice.data.favoriteClassified.data', EMPTY_ARRAY);

export const getFavoriteStore = state => get(state, 'myartprice.data.favoriteStore.data', EMPTY_ARRAY);

export const getFavoriteArtist = state => get(state, 'myartprice.data.favoriteArtist.data', EMPTY_ARRAY);

export const getLotSimilar = state => get(state, 'myartprice.data.similarLots.res', EMPTY_OBJECT);

export const getError = state => get(state, 'myartprice.data.error');

export const getLvl = state => get(state, 'myartprice.datas.lvl');
