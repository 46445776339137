import userContextReducer from './userContext';
import headerReducer from './header';
import footerReducer from './footer';
import searchReducer from './search';
import accountLotsListsReducer from './account/lotslists';
import artistReducer from './artist';
import promotionReducer from './promotion';
import homepageReducer from './homepage';
import paymentsReducer from './payments';
import artpriceReducer from './artprice';
import estimatesReducer from './estimates';

const sliceReducers = {
  userContext: userContextReducer,
  promotion: promotionReducer,
  artist: artistReducer,
  header: headerReducer,
  footer: footerReducer,
  accountLotsLists: accountLotsListsReducer,
  search: searchReducer,
  homepage: homepageReducer,
  payments: paymentsReducer,
  artprice: artpriceReducer,
  estimates: estimatesReducer,
};

export default sliceReducers;
