import { updateIndexesState } from './indexes';

export const UPDATE_REDUX_STORE = 'index/UPDATE_REDUX_STORE';
const simpleUpdateStore = data => ({
  type: UPDATE_REDUX_STORE,
  data,
});
const customUpdateStore = (action, data) => {
  switch (action) {
    case 'updateIndexesState':
      return updateIndexesState(data);
    default:
      throw new Error(`Unknown action [${action}] for customUpdateStore`);
  }
};

export const updateReduxStore = reduxStore => {
  if (!reduxStore) {
    return simpleUpdateStore(undefined);
  }
  const { __customAction, ...data } = reduxStore;
  if (__customAction) {
    // custom case
    return customUpdateStore(__customAction, data);
  }
  return simpleUpdateStore(data);
};
